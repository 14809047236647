<template>
  <section>
    <breadcrumb :title="$t('accounting')" :subtitle="$t('closures')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns is-12">
        <div class="column is-2">
          <b-field>
            <b-datepicker
                :placeholder="$t('date_init')"
                v-model="dateInit"
                trap-focus>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field>
            <b-datepicker
                :placeholder="$t('date_end')"
                v-model="dateEnd"
                trap-focus>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-2" v-if="user.userType === userTypes.MANAGER || user.userType === userTypes.AGENCY">
          <b-field>
            <multiselect
                :placeholder="$t(label_select)"
                v-model="agency"
                track-by="id"
                :multiple="true"
                :custom-label="stateName"
                open-direction="bottom"
                :show-no-results="false"
                :options="agencyList">
            </multiselect>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field>
            <multiselect
                :placeholder="$t('currency')"
                v-model="currency"
                open-direction="bottom"
                :show-no-results="false"
                :options="currencyList">
            </multiselect>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field>
            <multiselect
                :placeholder="$t('state')"
                v-model="state"
                :custom-label="setNameTransactionStatus"
                open-direction="bottom"
                :show-no-results="false"
                :options="stateList">
            </multiselect>
          </b-field>
        </div>
        <div class="column is-2 is-flex is-justify-content-space-between is-align-items-center">
          <div v-if="query || agencyListSelected.length || all" @click="getClosures(false)">
            <span class="mdi mdi-magnify"></span>
          </div>
          <button v-if="desserts.length" @click="generateReport" class="button new is-pink-light">
            <span class="mdi mdi-export"></span>
            {{ $t('export') }}
          </button>
        </div>
      </div>
      <div v-if="false" class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-tabs :class="{'is-vertical': desserts.length > 10}">
        <b-tab-item v-for="(item, idx) in desserts" :key="idx + 'a'">
          <template #header>
            {{ item.agencyName }}
          </template>
          <b-table
              :data="item.data"
              ref="table"
              :paginated="true"
              :current-page="currentPage"
              :per-page="itemsPerPage"
          >
            <b-table-column field="id" :label="$t('invoice')" width="40" numeric v-slot="props">
        <span class="col id">
           #{{ props.row.transactionNumber }}
        </span>
            </b-table-column>
            <b-table-column field="data" :label="$t('date')" v-slot="props">
            <span class="col date">
              {{ formatYYYYMMDD(props.row.date) }}
            </span>
            </b-table-column>
            <b-table-column field="issuer" :label="$t('issuer')" sortable v-slot="props">
         <span class="col blue">
            {{ props.row.sender }}
         </span>
            </b-table-column>
            <b-table-column field="recipient" :label="$t('recipient')" sortable v-slot="props">
        <span class="col blue">
           {{ props.row.receiver }}
        </span>
            </b-table-column>
            <b-table-column field="state" :label="$t('state')" sortable centered v-slot="props">
              <div>
                <b-tag type="is-tag-success success" rounded v-if="props.row.status===status.PENDING">
                  <span class="text-status pending"> {{ $t('pending') }}</span>
                </b-tag>
                <b-tag type="is-pink-light" rounded v-if="props.row.status===status.CANCELED">
                  <span class="text-status is-uppercase"> {{ $t('canceled') }}</span>
                </b-tag>
                <b-tag type="is-purple" rounded v-if="props.row.status===status.COMPLETED">
                  <span class="text-status is-uppercase"> {{ $t('delivered') }}</span>
                </b-tag>
                <b-tag type="is-green-success" rounded v-if="props.row.status===status.SHIPPING">
                  <span class="text-status is-uppercase"> {{ $t('distribution') }}</span>
                </b-tag>
                <b-tag type="is-danger" rounded v-if="props.row.status===status.ERROR">
                  <span class="text-status is-uppercase"> {{ $t('error') }}</span>
                </b-tag>
              </div>

            </b-table-column>
            <b-table-column field="amount" :label="$t('amount')" sortable v-slot="props">
                <span class="col cash">
                  {{ props.row.amount }} {{ props.row.currencyTo }}
                </span>
            </b-table-column>
            <b-table-column field="commission" :label="$t('commission')" sortable v-slot="props">
                <span class="col cash">
                  {{ props.row.rate }}
                </span>
            </b-table-column>
            <b-table-column field="to_pay" :label="$t('to_pay')" sortable v-slot="props">
                <span class="col cash">
                  {{ props.row.amountPay }} {{ props.row.currencyFrom }}
                </span>
            </b-table-column>
            <template #pagination>
              <b-pagination
                  :total="item.data.length"
                  v-model="currentPage"
                  :range-before="3"
                  :range-after="1"
                  order="is-right"
                  :per-page="itemsPerPage"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page">
              </b-pagination>
            </template>

          </b-table>
          <section class="footer-data" v-if="item.data.length">
            <hr class="hr-divider">
            <div class="columns">
              <div class="column is-one-quarter">
                <span class="total-balance">{{ $t('count_invoice') }}:</span>
                <span class="total-balance ml-2" v-if="item.agencySumary">{{ item.agencySumary.total }}</span>
              </div>
              <div class="column is-one-quarter is-flex">
                <span class="total-balance">{{ $t('amount') }}:</span>
                <div class="is-flex is-flex-direction-column">
            <span class="total-balance ml-2" v-for="(val, index) in item.agencySumary.importe" :key="index + 'd'">
              {{ val.totalAmount }} {{ val.currecyTo }}
            </span>
                </div>
              </div>
              <div class="column is-one-quarter">
                <span class="total-balance">{{ $t('to_pay') }}:</span>
                <span class="total-balance ml-2" v-if="item.agencySumary">{{
                    item.agencySumary.totalAmountPay.toFixed(2)
                  }} USD</span>
              </div>
              <div class="column is-one-quarter is-flex is-flex-direction-column">
                <div class="is-flex is-justify-content-flex-end">
                  <span class="total-balance">{{ $t('gain_agency') }}:</span>
                  <span class="total-balance ml-2" v-if="item.agencySumary">{{ item.agencySumary.earnings }} usd</span>
                </div>
                <div class="is-flex is-justify-content-flex-end">
                  <span class="total-balance">{{ $t('commission') }}:</span>
                  <span class="total-balance ml-2" v-if="item.agencySumary">{{
                      item.agencySumary.commission
                    }} usd</span>
                </div>
              </div>
            </div>
          </section>

        </b-tab-item>
      </b-tabs>

    </section>
    <paginate v-if="false" :count="totalRecords" :per_page="itemsPerPage" :current-page="currentPage"/>
    <div>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :manual-pagination="false"
          :pdf-quality="2"
          filename="Agency Closures Report"
          pdf-content-width="700px"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
          :html-to-pdf-options="optionsPdf"
      >
        <section slot="pdf-content">
          <section class="is-flex is-justify-content-space-between is-align-items-center ">
            <div class="report-log is-flex is-align-items-center">
              <div><img style=" width: 60px" :src="logo" alt=""></div>
              <div class="is-bold ml-4">{{ $t('report_agency') }}</div>
            </div>

            <div class="is-flex">
              <div class="is-bold">{{ $t('date') }}:</div>
              <div class="ml-2">{{ formatYYYYMMDD(newDate) }}</div>
            </div>
            <div class="is-flex">
              <div class="is-bold">{{ $t('hour') }}:</div>
              <div class="ml-2">{{ formatAMPM(newDate) }}</div>
            </div>
          </section>

          <section class="is-flex is-justify-content-space-between is-align-items-center mt-20">
            <div class="report-log is-flex is-flex-direction-column" v-if="dateInit && dateEnd">
              <div class="is-bold ml-4">{{ $t('range_date') }}</div>
              <div>{{ `${formatYYYYMMDD(dateInit)} 00:00:00` }} - {{ `${formatYYYYMMDD(dateEnd)} 11:59:59 PM` }}</div>
            </div>
            <div class="is-flex is-flex-direction-column">
              <div class="is-bold">{{ $t('state') }}:</div>
              <div class="ml-2" v-if="state">
                <span v-if="state.value===status.PENDING"> {{ $t('pending') }}</span>
                <span v-else-if="state.value===status.CANCELED"> {{ $t('canceled') }}</span>
                <span v-else-if="state.value===status.COMPLETED"> {{ $t('delivered') }}</span>
                <span v-else-if="state.value===status.SHIPPING"> {{ $t('distribution') }}</span>
                <span v-else-if="state.value===status.ERROR"> {{ $t('error') }}</span>
              </div>
              <div v-else>
                {{ $t('alls') }}
              </div>
            </div>
          </section>
          <template v-for="(items, index) in desserts">
            <section v-if="items.data.length" class="content-report mt-20" :key="index + 'e'">
              <div :key="index + 'f'" class="is-flex">
                <div class="is-bold">{{ $t('agency') }}:</div>
                <div class="ml-10">{{ items.agencyName }}</div>
              </div>
              <div :key="index + 'h'" class="columns" style="border-bottom: 1px solid #000000 !important;">
                <div class="column col report head">
                  {{ $t('invoice') }}
                </div>
                <div class="column col report head">
                  {{ $t('date') }}
                </div>
                <div class="column col report head">
                  {{ $t('issuer') }}
                </div>
                <div class="column col report head">
                  {{ $t('recipient') }}
                </div>
                <div class="column col report head">
                  {{ $t('state') }}
                </div>
                <div class="column col report head">
                  {{ $t('amount') }}
                </div>
                <div class="column col report head">
                  {{ $t('commission') }}
                </div>
                <div class="column col report head">
                  {{ $t('to_pay') }}
                </div>
              </div>
              <div class="columns row-report" v-for="(item, idx1) in items.data" :key="idx1 + 'b'">
                <div class="column col report">
                  {{ item.transactionNumber }}
                </div>
                <div class="column col report">
                  {{ formatYYYYMMDD(item.date) }}
                </div>
                <div class="column col report">
                  {{ item.sender }}
                </div>
                <div class="column col report">
                  {{ item.receiver }}
                </div>
                <div class="column col report">
                  <span class="col report" v-if="item.status===status.PENDING"> {{ $t('pending') }}</span>
                  <span class="col report" v-else-if="item.status===status.CANCELED"> {{ $t('canceled') }}</span>
                  <span class="col report" v-else-if="item.status===status.COMPLETED"> {{ $t('delivered') }}</span>
                  <span class="col report" v-else-if="item.status===status.SHIPPING"> {{ $t('distribution') }}</span>
                  <span class="col report" v-else-if="item.status===status.ERROR"> {{ $t('error') }}</span>
                </div>
                <div class="column col report">
                  {{ item.amount }} {{ item.currencyTo }}
                </div>
                <div class="column col report">
                  {{ item.rate }}
                </div>
                <div class="column col report">
                  {{ item.amountPay }}
                </div>
              </div>
              <section :key="index + 'i'" class="mt-20 pa-10" style="border: 1px solid" v-if="items.data.length">
                <div class="columns">
                  <div class="column">
                    <span class="is-bold fz-12">{{ $t('count_invoice') }}:</span>
                    <span class="fz-12 ml-2" v-if="items.agencySumary">{{ items.agencySumary.total }}</span>
                  </div>
                  <div class="column is-flex">
                    <span class="is-bold fz-12">{{ $t('amount') }}:</span>
                    <div class="is-flex is-flex-direction-column">
                          <span class="fz-12 ml-2" v-for="(val, index) in items.agencySumary.importe"
                                :key="index + 'c'">
                            {{ val.totalAmount }} {{ val.currecyTo }}
                          </span>
                    </div>
                  </div>
                  <div class="column">
                    <span class="is-bold fz-12">{{ $t('to_pay') }}:</span>
                    <span class="fz-12 ml-2" v-if="items.agencySumary">{{
                        items.agencySumary.totalAmountPay
                      }} USD</span>
                  </div>
                  <div class="column">
                    <div class="is-flex is-justify-content-flex-end">
                      <span class="is-bold fz-12">{{ $t('commission') }}:</span>
                      <span class="fz-12 ml-2" v-if="items.agencySumary">{{ items.agencySumary.commission }} usd</span>
                    </div>
                  </div>
                </div>
              </section>
            </section>
            <div v-if="items.data.length-1>index" :key="index + 'g'" class="html2pdf__page-break"></div>
          </template>
          <section class="mt-20 pa-20" style="border: 1px solid">
            <div class="columns ">
              <div class="column is-flex is-justify-content-center">
                        <span class="is-bold fz-12">
                          {{ $t('total_summary') }}
                        </span>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <span class="is-bold fz-12">{{ $t('count_invoice') }}:</span>
                <span class="fz-12 ml-2" v-if="totalInvoice">{{ totalInvoice }}</span>
              </div>

              <div class="column">
                <span class="is-bold fz-12">{{ $t('to_pay') }}:</span>
                <span class="fz-12 ml-2" v-if="totalToPay">{{ totalToPay.toFixed(2) }} USD</span>
              </div>

            </div>
          </section>
        </section>
      </vue-html2pdf>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {mapActions, mapGetters} from "vuex";
import {TRANSACTION_STATUS} from "@/enum/transactionStatus";
import Multiselect from "vue-multiselect";
import {USER_TYPE} from "@/enum/userType";
import VueHtml2pdf from 'vue-html2pdf'
import logo from "@/assets/remisario/fullcolor/imagotipo.jpg";
import {CURRENCY} from "@/enum/currency";

export default {
  name: "ClosuresComponent",
  components: {Paginate, Breadcrumb, Multiselect, VueHtml2pdf},
  data() {
    return {
      logo,
      dateInit: null,
      dateEnd: null,
      agencyList: [],
      agencyListSelected: [],
      agency: null,
      all: false,
      state: null,
      newDate: new Date(),
      userTypes: USER_TYPE,
      currencyList: Object.values(CURRENCY),
      currency: null,
      agencySumary: null,
      commission: null, //temp
      earnings: null, //temp
      totalSumary: null,
      agencyName: null,
      propsModals: {},
      itemsPerPage: 30,
      currentPage: 1,
      totalPages: 0,
      totalToPay: 0,
      totalInvoice: 0,
      totalRecords: 0,
      status: TRANSACTION_STATUS,
      query: null,
      desserts: [],
      label_select: null,
      optionsPdf: {
        margin: [10, 10],
        filename: `Agency Closures Report`,
        pagebreak: {mode: ['avoid-all', 'css', 'legacy']}
      }

    }
  },
  watch: {
    dateInit: function () {
      if (this.query !== null) {
        this.query += `&initDate=${this.formatYYYYMMDD(this.dateInit)}`
      } else {
        this.query = `?initDate=${this.formatYYYYMMDD(this.dateInit)}`
      }
    },
    dateEnd: function () {
      if (this.query !== null) {
        this.query += `&finalDate=${this.formatYYYYMMDD(this.dateEnd)}`
      } else {
        this.query = `?finalDate=${this.formatYYYYMMDD(this.dateEnd)}`
      }
    },
    agency: function () {
      if (this.agency[0].id === 0) {
        this.agencyListSelected = []
        this.all = true
      } else {
        this.agencyListSelected = []
        this.agency.forEach(item => {
          this.agencyListSelected.push(item.id)
        })
      }

    },
    state: function () {
      if (this.query !== null) {
        this.query += `&status=${this.state.value}`
      } else {
        this.query = `?status=${this.state.value}`
      }
    },
    currency: function () {
      if (this.query !== null) {
        this.query += `&currency=${this.currency}`
      } else {
        this.query = `?currency=${this.currency}`
      }
    }
  },

  methods: {
    ...mapActions({
      fetchClosures: 'FETCH_CLOSURES',
      fetchAgency: 'FETCH_AGENCY',
      fetchTotalRemittances: 'FETCH_TOTAL_REMITTANCES_DASH'

    }),
    stateName({name}) {
      return name
    },
    onProgress(event) {
      console.log(event);
    },
    hasGenerated(event) {
      console.log(event);
    },
    hasStartedGeneration() {
    },
    generateReport() {
      this.filename = 'Agency Report'
      this.calcTotalToPay().then(value => {
        console.log(value);
        this.$refs.html2Pdf.generatePdf()
      })
    },
    setNameTransactionStatus({value}) {
      switch (value) {
        case TRANSACTION_STATUS.COMPLETED:
          return this.lang === 'es' ? 'Completado' : 'Completed';
        case TRANSACTION_STATUS.CANCELED:
          return this.lang === 'es' ? 'Cancelado' : 'Canceled';
        case TRANSACTION_STATUS.PENDING:
          return this.lang === 'es' ? 'Pendiente' : 'Pending';
        case TRANSACTION_STATUS.SHIPPING:
          return this.lang === 'es' ? 'Enviando' : 'Shipping';
        case TRANSACTION_STATUS.ERROR:
          return 'Error';

      }
    },
    calcTotalToPay() {
      return new Promise(resolve => {
        this.desserts.forEach(value => {
          this.totalToPay += value.totalSumary.totalAmountPay
          this.totalInvoice += value.agencySumary.total
        })
        resolve({totalPay: this.totalToPay, totalInvoice: this.totalInvoice})
      })
    },
    getClosures(firts = false) {
      if (this.query || this.agencyListSelected.length || this.all) {
        let data = {
          'agencyIds': this.agencyListSelected,
          'query': this.query,
          'userType': this.user.userType,
          'currentAgency': this.user.userType !== USER_TYPE.AGENT ? firts : false
        }
        this.fetchClosures(data)
            .then(value => {
              this.desserts = []
              value.forEach(item => {
                if (item.report.length) {
                  this.desserts.push({
                    data: item.report,
                    agencySumary: item.agencySummaries[0],
                    totalSumary: item.agencySummaries[0],
                    agencyName: item.agencyName
                  })
                }
              })
              this.query = null
              this.all = false
            })
      }

    },
    formatYYYYMMDD(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
    formatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return `${hours}:${minutes}:${seconds} ${ampm}`
    },
    getAgencies(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
        userType: this.user.userType
      };
      return new Promise(resolve => {
        this.fetchAgency(data).then(value => {
          resolve(value)
        })
      })
    },
  },
  computed: {
    ...mapGetters({
      user: 'GET_USER',
      lang: 'GET_LANG',
      stateList: 'GET_STATUS_TRANSACTION'
    })
  },
  created() {
    this.label_select = this.user.userType === USER_TYPE.AGENCY ? 'agent' : 'agency'
    if (this.user.userType === USER_TYPE.MANAGER) {
      this.query = null
    } else {
      this.query = this.user.userType === USER_TYPE.AGENCY ? `?agencyId=${this.user.id}` : `?agentId=${this.user.id}`
    }
    this.getClosures(true)
    this.getAgencies()
        .then(value => {
          this.agencyList = []
          let name = this.lang === 'es' ? 'Seleccionar todo' : 'Select All'
          this.agencyList.push({name: name, id: 0})
          value.data.forEach(item => {
            this.agencyList.push(item)
          })
        })
  }

}
</script>

<style scoped lang="scss">

.row-report {
  border-bottom: 1px solid;

}

.col {
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;

  &.report {
    color: #000000;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0;
  }

  &.head {
    font-weight: bold;
  }

  &.id {
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    color: #C1C1C1;
  }

  &.blue {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }


  &.date {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  &.cash {
    font-weight: normal;
    font-size: 16px;
    text-align: center;
  }
}

.hr-divider {
  height: 1px;
  background: #AEAEAE;
}

.total-balance {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #AEAEAE;
}

.button {
  &.new {
    width: 150px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: 0.5px;
    color: #FFFFFF;
  }

  .mdi-export {
    font-size: 38px;
  }
}

.mdi-magnify {
  font-size: 38px;
}

.text-status {
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: bold;

  &.pending {
    color: #289BA4;
  }

}

.tag:not(body).is-rounded {
  border-radius: 290486px;
  min-width: 125px;
}

.table-scroll {
  overflow-y: auto;
  max-height: 45vh;
}

//.footer-data {
//  //width: 100%;
//  position: fixed;
//  bottom: 30px;
//}
</style>
